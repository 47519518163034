@import 'consts.scss';

body {
  font-family: 'Inter';
  font-size: 14px;
  line-height: 1.6rem;
  color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  line-height: inherit;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.4rem;
}

p {
  line-height: 1.57;
}

form {
  .form-elm {
    position: relative;
  }
}

.form-group {
  label {
    display: block;
    font-size: 1.5rem;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  span {
    display: block;
    line-height: 1.5;
  }

  input,
  select,
  .c-input,
  .c-select {
    width: 100%;
  }
}

input {
  &::placeholder {
    color: $color-gray-medium;
  }
}

textarea {
  width: 100%;
}

// MARGIN & PADDINGS
@for $i from 1 through 6 {
  .mt-#{$i} {
    margin-top: $gutter * $i;
  }
  .mr-#{$i} {
    margin-right: $gutter * $i;
  }
  .mb-#{$i} {
    margin-bottom: $gutter * $i;
  }
  .ml-#{$i} {
    margin-left: $gutter * $i;
  }
  .pt-#{$i} {
    padding-top: $gutter * $i;
  }
  .pr-#{$i} {
    padding-right: $gutter * $i;
  }
  .pb-#{$i} {
    padding-bottom: $gutter * $i;
  }
  .pl-#{$i} {
    padding-left: $gutter * $i;
  }
}

// GRID

.rows-spaced {
  & > * {
    margin-bottom: $grid-gutter-height;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Not Equal Sizes. Ej: col-2-3 = Width 2/3
.cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $grid-gutter-height * (-1);
  margin-left: $grid-gutter-width * (-1);

  & > * {
    min-width: $grid-column-mininum-width - $grid-gutter-width;
    margin-left: $grid-gutter-width;
  }

  @for $i from 2 through 10 {
    @for $j from 1 through $i - 1 {
      .col-#{$j}-#{$i} {
        width: calc(100% / #{$i} * #{$j} - 16px);
        min-width: $grid-column-mininum-width;
        margin-top: $grid-gutter-height;
      }
    }
  }
}

// Equal Sizes. Ex: cols-2, cols-3
@for $i from 2 through 10 {
  .cols-#{$i} {
    width: calc(100% + #{$grid-gutter-width});
    display: flex;
    flex-wrap: wrap;
    margin-top: $grid-gutter-height * (-1);
    margin-left: $grid-gutter-width * (-1);

    & > * {
      width: calc(100% / #{$i} - #{$grid-gutter-width});
      min-width: $grid-column-mininum-width;
      margin-top: $grid-gutter-height;
      margin-left: $grid-gutter-width;

      @media (max-width: '576px') {
        min-width: auto;
      }
    }
  }
}

@each $breakpoint in map-keys($screen-breakpoints) {
  .col-#{$breakpoint}-full {
    @media (max-width: map-get($screen-breakpoints, $breakpoint)-1+'px') {
      width: 100%;
    }
  }

  .cols-#{$breakpoint}-half > * {
    @media (max-width: map-get($screen-breakpoints, $breakpoint)-1+'px') {
      width: calc(50% - #{$grid-gutter-width});
    }
  }

  .cols-#{$breakpoint}-full {
    @media (max-width: map-get($screen-breakpoints, $breakpoint)-1+'px') {
      width: 100%;
      margin-left: 0;

      & > * {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

// COMPONENTS

.os-scrollbar {
  .os-scrollbar-track {
    background: rgba(0, 0, 0, 0.1) !important;
  }

  .os-scrollbar-handle {
    background: rgba(0, 0, 0, 0.2) !important;

    &:hover {
      background: rgba(0, 0, 0, 0.4) !important;
    }
  }
}

.os-scrollbar-vertical {
  width: 8px !important;
  margin-right: 1px !important;
}

.os-scrollbar-horizontal {
  height: 8px !important;
}

.c-button {
  background-color: $button-primary;
  color: $color-white;
  font-size: 1.2rem;
  font-weight: 800;

  &:hover {
    background-color: $button-primary-hover;
  }

  &:active {
    background-color: $button-primary-pressed;
  }

  &:disabled {
    color: $button-disabled-font;
  }

  &.secondary {
    background-color: $button-secondary;
    color: $button-secondary-font;

    &:hover {
      opacity: 0.6;
    }

    &:active {
      opacity: 1;
      background-color: $button-secondary-pressed;
    }
  }

  &.white {
    background-color: $button-white;
    color: $button-white-font;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
      color: $button-white-pressed-font;
    }

    &:disabled {
      background-color: inherit;
      color: inherit;
      opacity: 0.3;
    }
  }

  &.small {
    min-width: 24px;
    height: 24px;
    background-color: #e6e6e6;
    border-radius: 4px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.c-checkbox {
  margin-right: 5px;
}

.c-fieldset {
  label {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 8px;
    font-weight: 700;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.c-file-uploader {
  .msg {
    font-size: 1.5rem;
  }
}

.c-fieldset {
  .legend {
    color: $color-blue;
  }
}

.c-select {
  select {
    color: $color-black;
    &[data-error='true'] {
      border: 1px solid $color-error !important;
    }
  }
}
.c-input {
  & input {
    color: $color-black;

    &[data-error='true'] {
      border: 1px solid $color-error !important;
    }
  }

  &:hover:before {
    transition: all 200 linear 0ms;
    border-bottom: 2px solid rgba(70, 119, 143, 1);
  }

  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    transform: scaleX(0);
    padding-right: inherit;

    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid $color-blue;
  }

  &.noborder {
    border: 0 !important;

    &:before,
    &:after {
      border: 0 !important;
    }
  }

  input:disabled {
    background-color: $input-disabled;
  }

  &::placeholder {
    color: $color-gray-medium;
  }
}

.c-input label,
.c-textarea label,
.c-select label,
.c-input-range label,
.c-radio__label {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 1.3rem;
  display: block;
}

.c-input label:first-letter,
.c-textarea label:first-letter,
.c-select label:first-letter,
.c-input-range label:first-letter {
  text-transform: uppercase;
}

.c-list-limiter {
  min-width: 0;

  select {
    padding: 0 4px;
  }
}

.c-modal {
  z-index: $zindex-modal !important;

  .close {
    filter: invert(65%) sepia(4%) saturate(26%) hue-rotate(324deg) brightness(94%) contrast(82%);

    &:hover {
      filter: none;
    }
  }

  .inner > * {
    margin-bottom: $grid-gutter-height;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;

    button {
      margin-left: 16px;
    }
  }

  h1 {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-gray-light;
    font-size: 1.8rem;
  }
}

.c-select {
  select:disabled {
    background-color: $input-disabled;
  }
}

.c-spinner {
  z-index: $zindex-spinner !important;

  &.global {
    width: 100vw;
    height: 100vh;
    position: fixed;
  }
}

.c-textarea {
  label {
    margin-bottom: 8px;
  }

  textarea {
    font-family: 'Inter';
  }
}

.leaflet-default-icon-path {
  background-image: url(/assets/images/marker-icon.png);
}

main {
  width: 100%;
  height: 100%;
}

.main-wrapper {
  width: calc(100% - #{$sidebar-width});
  flex-grow: 1;

  .content-wrapper {
    display: flex;
    position: relative;
  }

  main.content {
    height: 100%;
    min-height: calc(100vh - #{$topbar-height});
    padding: $grid-gutter-width + $grid-gutter-height;

    &.blue {
      padding: $grid-gutter-width;
      background-color: $body-background;
    }
    &.gray {
      padding: $grid-gutter-width;
      background-color: $color-gray-light2;
    }
  }
}

.listado-wrapper {
  padding: 16px;
  background-color: #fff;
  border-radius: 12px;
}

.no-results {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  .msg {
    padding-left: 64px !important;
    display: flex;
    align-items: center;
    background: url(/assets/icons/icon-info.svg) left center/auto 50px no-repeat;
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.ic-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $color-blue;
  border-radius: 3px;

  .c-icon {
    filter: invert(41%) sepia(0%) saturate(0%) hue-rotate(258deg) brightness(94%) contrast(86%);
  }

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 1;
    border-color: $color-blue;
  }

  &:disabled,
  &.disabled {
    color: inherit;
    opacity: 0.4;
    cursor: not-allowed;
  }
}
