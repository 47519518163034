*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

a,
button {
  cursor: pointer;
}

a,
a:hover {
  color: inherit;
  text-decoration: inherit;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

button {
  user-select: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

input[type='checkbox'] {
  cursor: pointer;
}

img {
  vertical-align: middle;
  border-style: none;
  -webkit-user-drag: none;
}

ul,
li {
  display: inline;
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

textarea {
  overflow: auto;
  resize: vertical;
}

/* Generic classes */

.capitalize {
  text-transform: capitalize;
}

.clearfix {
  width: 0;
  height: 0;
  clear: both;
  display: block;
  float: none;
  overflow: hidden;
  visibility: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
  border: 0;
  font-size: 0;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.flex {
  display: flex;
  flex-wrap: wrap;

  &.around {
    justify-content: space-around;
  }

  &.basis100 {
    flex-basis: 100%;
  }

  &.between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  &.column {
    flex-direction: column;
  }

  .grow-2 {
    flex-grow: 2;
  }

  .grow-3 {
    flex-grow: 3;
  }

  .grow-4 {
    flex-grow: 4;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  &.end {
    justify-content: flex-end;
  }

  &.evenly {
    justify-content: space-between;

    &:before,
    &:after {
      content: '';
      display: block;
    }
  }

  &.h-center {
    justify-content: center;
  }

  &.start {
    justify-content: flex-start;
  }

  &.v-center {
    align-items: center;
  }

  &.v-start {
    align-items: flex-start;
  }
}

.h100 {
  min-height: 100% !important;
}

.hidescroll {
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.hidden,
[hidden] {
  display: none !important;
}

.visible,
[visible] {
  display: inherit !important;
}

.noscroll {
  overflow: hidden !important;
}

.overflow-hidden {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.uc-first:first-letter {
  text-transform: uppercase;
}

.visible {
  visibility: visible !important;
}

.w100 {
  min-width: 100% !important;
}
