.App {
  .general-wrapper {
    width: 100%;
    max-width: 1008px;
    margin: 0 40px;
  }
  .header {
    width: 100%;
    height: 96px;
    background: #1b53a6;
    padding: 0 40px;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    font-size: 1.5rem;
    font-weight: 800;
    letter-spacing: 0.18px;
    .nav {
      width: 50%;
      button {
        width: 124px;
        height: 32px;
        border-radius: 6px;
        border: none;
        font-size: 1.2rem;
        font-weight: 800;
        letter-spacing: 0.2px;
      }
    }
  }
  .first-section {
    margin-top: 96px;
    font-size: 1.6rem;
    color: white;
    background: url('/assets/fs-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      margin-top: 192px;
      margin-bottom: 32px;
      font-size: 1.7rem;
      font-weight: 800;
      line-height: 1.41;
      color: white;
    }
    h1 {
      margin-bottom: 32px;
      font-size: 4.3rem;
      font-weight: 600;
      letter-spacing: 0.57px;
      line-height: 1.33;
      color: white;
    }
    .text {
      width: 60%;
      margin-bottom: 356px;
      line-height: 1.5;
    }
  }
  .calendar {
    margin-top: -216px;
    .wrapper-image {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .subtitle {
    margin-bottom: 32px;
    font-size: 1.7rem;
    font-weight: 800;
    letter-spacing: 0.2px;
    color: #4d88e2;
  }
  h2 {
    margin-bottom: 72px;
    font-size: 3.4rem;
    font-weight: 600;
    letter-spacing: 0.45px;
    line-height: 1.41;
  }
  .second-section {
    margin-top: -20px;
    padding-top: 120px;
    h2 {
      text-align: center;
    }
    .grid__item {
      margin-bottom: 64px;
    }
    .wrapper-icon {
      width: 64px;
      height: 64px;
      margin-bottom: 25px;
      border-radius: 16px;
      background: #f1f6fd;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item__title {
      margin-bottom: 15px;
      font-size: 1.9rem;
      font-weight: 600;
      line-height: 1.05;
      letter-spacing: 0.14px;
    }
    .item__text {
      font-size: 1.6rem;
      line-height: 1.5;
    }
  }
  .third-section {
    margin-top: -20px;
    padding-top: 115px;
    .subtitle {
      text-align: center;
    }
    h2 {
      text-align: center;
    }
    &__grid {
      .grid__item {
        padding: 24px 20px;
        font-size: 2.1rem;
        font-weight: 600;
        line-height: 1.38;
        letter-spacing: 0.15px;
        color: #999999;
        &.blue {
          background: #f1f6fd;
          color: #4d88e2;
        }
      }
    }
  }
  .fourth-section {
    margin-top: 200px;
    margin-bottom: 225px;
    background: url('/assets/fourth-section-bg.jpg');
    background-repeat: no-repeat;
    background-size: 70%;
    padding-top: 327px;
    h2 {
      margin-bottom: 32px;
    }
    .data {
      width: 80%;
      background-color: #f1f6fd;
      padding: 72px 102px;
    }
    .values {
      margin-top: 42px;
      margin-bottom: 40px;
      font-size: 2.6rem;
      font-weight: 600;
      letter-spacing: 0.32px;
      &__title {
        font-size: 1.9rem;
        font-weight: 600;
        letter-spacing: 0.22px;
        margin-bottom: 16px;
      }
    }
    .cols-2 > div {
      max-width: 285px;
    }
  }
  .footer {
    width: 100%;
    background: #1b53a6;
    padding: 104px 112px 72px;
    color: white;
    .cols {
      border-bottom: 2px solid #4d88e2;
      padding-bottom: 140px;
      margin-bottom: 24px;
      .col-1-4,
      .col-2-4 {
        margin-top: 0;
      }
    }
    &__text {
      width: 80%;
      font-size: 2.7rem;
      font-weight: 600;
      letter-spacing: 0.36px;
      line-height: 1.74;
    }
    &__contact {
      font-size: 1.6rem;
      line-height: 1.5;
      color: #dbe7f9;
      > div {
        margin-bottom: 16px;
      }
      .contact__title {
        margin-top: 10px;
        margin-bottom: 25px;
        font-size: 1.9rem;
        font-weight: 600;
        color: white;
        letter-spacing: 0.22px;
        line-height: 1.26;
      }
    }
    &__lower {
      > div {
        font-size: 1.6rem;
        line-height: 1.5;
        color: #dbe7f9;
        margin-right: 48px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
